<template>
  <div id="edit-value" class="customScrollBar">
    <div
      class="line"
      v-for="(item, index) in data"
      :style="{ width: item.width }"
      :key="index"
    >
      <div class="title">
        <div class="notnull" v-if="item.isNotNull">*</div>
        {{ item.title }}:
      </div>
      <div class="value">
        <el-input
          v-if="item.type == 'input'"
          v-model="item.value"
          :placeholder="'请输入' + item.title"
          :disabled="item.isDisable"
          clearable
        ></el-input>
        <el-input
          v-if="item.type == 'password'"
          show-password
          v-model="item.value"
          :placeholder="'请输入' + item.title"
          :disabled="item.isDisable"
          clearable
        ></el-input>

        <el-radio-group
          v-if="item.type == 'radio'"
          v-model="item.value"
          :disabled="item.flag"
        >
          <el-radio
            :label="it.value"
            v-for="(it, ii) in item.options"
            :key="ii"
            >{{ it.label }}</el-radio
          >
        </el-radio-group>
        <el-input
          v-if="item.type == 'textarea'"
          v-model="item.value"
          :placeholder="'请输入' + item.title"
          :disabled="item.isDisable"
          type="textarea"
          maxlength="1000"
          show-word-limit
          :rows="4"
          clearable
        ></el-input>
        <!-- 项目 -->
        <el-select
          v-if="item.type == 'tags'"
          @focus="tagfocus(item.key)"
          v-model="item.value"
          multiple
          :placeholder="'请选择' + item.title"
        >
          <el-option
            v-for="it in item.options"
            :key="it.id"
            :label="it.name"
            :value="it.id"
          >
          </el-option>
        </el-select>
        <!-- 计数器 -->
        <el-input-number
          v-if="item.type == 'input-number'"
          v-model="item.value"
          :min="1"
          :max="20"
          label="描述文字"
        ></el-input-number>

        <div class="richtext" v-if="item.type == 'richtext'">
          <span @click="openUeditor(item)">查看详情</span>
        </div>

        <!-- 图片上传 -->
        <div class="imgs" v-if="item.type == 'imgArray'">
          <div class="img" v-for="(img, index) in item.value" :key="index">
            <img :src="img" alt="" />
            <div class="mb">
              <i class="el-icon-delete" @click="deleteImg(item, index)"></i>
            </div>
          </div>
          <div class="img">
            <el-upload
              class="upload-demo"
              :action="item.uploadData.action"
              :headers="item.uploadData.headers"
              :show-file-list="false"
              multiple
              :data="item.uploadData.data"
              :before-upload="beforeAvatarUpload"
              :on-success="handleAvatarSuccess"
              :on-error="handleError"
            >
              <i class="el-icon-upload"></i>
            </el-upload>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    editValueData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      data: this.editValueData,
    };
  },
  watch: {
    editValueData() {
      this.data = this.editValueData;
    },
    data(newValue) {
      this.$emit("showEditValueData", this.data);
    },
  },
  methods: {
    openUeditor(item) {
      this.$emit("openUeditor", item);
    },
    handleAvatarSuccess(response, file, fileList) {
      this.$emit("handleAvatarSuccess", response, file, fileList);
    },
    handleError(err, file, fileList) {
      console.log("文件上传失败", err);
      this.$emit("handleError");
    },
    beforeAvatarUpload(file) {
      this.$emit("beforeAvatarUpload", file);
    },
    tagfocus(key) {
      this.$emit("tagfocus", key);
    },
    deleteImg(item, index) {
      this.$emit("deleteImg", item, index);
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
#edit-value {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  align-content: flex-start;

  > .line {
    // width: 100%;
    height: auto;
    display: flex;
    margin-top: 10px;

    > .title {
      width: 220px;
      // height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;

      margin-right: 10px;
      margin-top: 8px;
      font-size: 15px;

      > .notnull {
        color: #f00;
        margin-right: 5px;
        margin-top: 3px;
      }
    }

    > .value {
      width: 0;
      flex: 1;
      height: auto;
      min-height: 40px;
      display: flex;
      align-items: flex-start;

      /deep/.el-input__inner,
      /deep/.el-textarea__inner {
        border: none;
        background-color: #f6f9ff;
        border-radius: 2px;
      }

      /deep/.el-select,
      /deep/.el-date-editor.el-input,
      /deep/ .el-date-editor.el-input__inner {
        width: 100%;
      }

      /deep/.el-radio-group {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 5px;
      }

      > .richtext {
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #4099ff;
        cursor: pointer;
      }

      > .imgs {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
        box-sizing: border-box;

        .img {
          width: 150px;
          height: 150px;
          background-color: #f6f9ff;
          margin: 6px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;

          > img {
            width: 100%;
          }

          i {
            font-size: 20px;
          }

          > .mb {
            width: 100%;
            position: absolute;
            bottom: 0;
            height: 40px;
            background-color: #2e2e2e2c;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
