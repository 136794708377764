//注册全局公用组件
import Vue from "vue";

import DiyDialog from './diy_dialog/diyDialog'
Vue.component('diy-dialog', DiyDialog)

import TopValue from './top_value/topValue.vue'
Vue.component('top-value', TopValue)

import EditValue from './edit_value/editValue.vue'
Vue.component('edit-value', EditValue)

import UeditorDialog from './ueditor_dialog/ueditorDialog.vue'
Vue.component('ueditor-dialog', UeditorDialog)