import Vue from "vue";

/**
 * 项目列表界面基础数据
 * @returns
 */
export function getInitTableData() {
  let data = {
    topSearchData: [
      {
        title: "标签名称",
        type: "input",
        value: "",
        key: "name",
        clearable: true,
      },
    ],
    topButtonData: [
      {
        title: "查询",
        icon: "el-icon-search",
      },
      {
        title: "添加",
        icon: "el-icon-plus",
      },
    ],
    tableMenu: [
      {
        key: "name",
        title: "标签名称",
        isColor: false,
        isChange: false,
        width: "",
      },
      {
        key: "orderNum",
        title: "顺序",
        isColor: false,
        isChange: false,
        width: "",
      },
    ],
   
  };
  return data;
}

/**
 * 初始化项目编辑界面基础数据
 * @returns
 */
export function initEditData() {
  let data = [
    {
      title: "标签顺序",
      key: "orderNum",
      type: "input-number",
      value: "",
      width: "100%",
      isNotNull: true,
    },
    {
      title: "标签名称",
      key: "name",
      type: "input",
      value: "",
      width: "50%",
      isNotNull: true,
    },
  ];
  return data;
}

/**
 * 获取标签列表
 * @param sendData
 * @returns {Promise<*>}
 */

export async function getTagsListByPage(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/tags/getTagsListByPage",
    headers: {
      isToken: true
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}


/**
 * 批量增加或修改标签
 * @param sendData
 * @returns {Promise<*>}
 */

export async function addOrUpdateTagsList(sendData) {
    return await Vue.prototype.$request({
      url: "/tourism/tags/addOrUpdateTagsList",
      headers: {
        isToken: true
      },
      method: "post",
      timeout: 20000,
      data: sendData,
    });
  }

  /**
 * 批量删除标签
 * @param sendData
 * @returns {Promise<*>}
 */

export async function delTagsList(sendData) {
    return await Vue.prototype.$request({
      url: "/tourism/tags/delTagsList",
      headers: {
        isToken: true
      },
      method: "post",
      timeout: 20000,
      data: sendData,
    });
  }