import { options } from "less";
import Vue from "vue";
/**
 * 项目列表界面基础数据
 * @returns
 */
export function getInitTableData() {
  let data = {
    topSearchData: [
      {
        title: "订单号",
        type: "input",
        value: "",
        key: "id",
        clearable: true,
      },
      {
        title: "项目名称",
        type: "input",
        value: "",
        key: "projectName",
        clearable: true,
      },
      {
        title: "订单状态",
        type: "select",
        value: "",
        key: "status",
        clearable: true,
        options: [
          { label: "待付款 ", value: "待付款" },
          { label: "待出行", value: "待出行" },
          { label: "已完成", value: "已完成" },
        ],
      },
    ],
    topButtonData: [
      {
        title: "查询",
        icon: "el-icon-search",
      },
      // {
      //   title: "添加",
      //   icon: "el-icon-plus",
      // },
      {
        title: "导出",
        icon: "el-icon-upload2",
      },
    ],
    tableMenu: [
      {
        key: "id",
        title: "订单号",
        isColor: false,
        isChange: false,
        width: "200",
      },
      {
        key: "projectName",
        title: "项目名称",
        isColor: false,
        isChange: false,
        width: "200",
      },
      // {
      //   key: "orderType",
      //   title: "订单类型",
      //   isColor: false,
      //   isChange: false,
      //   width: "80",
      // },
      {
        key: "startDate",
        title: "开始时间",
        isColor: false,
        isChange: false,
        width: "180",
      },

      {
        key: "endDate",
        title: "结束时间",
        isColor: false,
        isChange: false,
        width: "180",
      },
      {
        key: "number",
        title: "人数",
        isColor: false,
        isChange: false,
        width: "80",
      },
      {
        key: "name",
        title: "姓名",
        isColor: false,
        isChange: false,
        width: "80",
      },

      {
        key: "phone",
        title: "手机号",
        isColor: false,
        isChange: false,
        width: "100",
      },
      {
        key: "city",
        title: "出发地",
        isColor: false,
        isChange: false,
        width: "180",
      },
      {
        key: "groggery",
        title: "酒店",
        isColor: false,
        isChange: false,
        width: "80",
      },
      {
        key: "flight",
        title: "航班号",
        isColor: false,
        isChange: false,
        width: "",
      },
      {
        key: "isPickup",
        title: "是否接机",
        isColor: true,
        isChange: true,
        width: "80",
      },
      {
        key: "remark",
        title: "备注",
        isColor: false,
        isChange: false,
        width: "80",
      },

      {
        key: "estimatedTotal",
        title: "预计总额",
        isColor: false,
        isChange: false,
        width: "",
      },
      {
        key: "status",
        title: "状态",
        isColor: false,
        isChange: false,
        width: "80",
      },
      // {
      //   key: "touristinformation",
      //   title: "新增旅客信息",
      //   isColor: false,
      //   isChange: false,
      //   width: "80",
      // },
    ],
  };
  return data;
}

/**
 * 初始化项目编辑界面基础数据
 * @returns
 */
export function initEditData() {
  let data = [
    {
      title: "项目名称",
      key: "name",
      type: "input",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "项目标价",
      key: "price",
      type: "input",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "项目实际价格",
      key: "preferentialPrice",
      type: "input",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "景点封面",
      key: "titleImage",
      type: "imgArray",
      value: [],
      width: "70%",
      isNotNull: true,
      uploadData: {
        action: process.env.VUE_APP_BASE_API + "/tourism/file/uploadFile",
        width: "480px",
        height: "270px",
        headers: {
          Authorization: "Training " + localStorage.getItem("isToken"),
        },
        data: {
          key: "imgs",
          title: "景点封面",
        },
      },
    },
    {
      title: "景点轮播图",
      key: "images",
      type: "imgArray",
      value: [],
      width: "70%",
      isNotNull: true,
      uploadData: {
        action: process.env.VUE_APP_BASE_API + "/tourism/file/uploadFile",
        width: "480px",
        height: "270px",
        headers: {
          Authorization: "Training " + localStorage.getItem("isToken"),
        },
        data: {
          key: "images",
          title: "景点轮播图",
        },
      },
    },
    {
      title: "项目标签",
      key: "tagsId",
      type: "tags",
      value: [],
      width: "70%",
      options: [],
      isNotNull: true,
    },
    {
      title: "产品特色",
      key: "productFeatures",
      type: "richtext",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "项目描述",
      key: "description",
      type: "textarea",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "温馨提示",
      key: "warmReminder",
      type: "richtext",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "游客点评",
      key: "commentOn",
      type: "richtext",
      value: "",
      width: "70%",
      isNotNull: true,
    },
  ];
  return data;
}

/**
 * 获取订单列表
 * @param sendData
 * @returns {Promise<*>}
 */

export async function getOrdersListByPage(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/orders/getOrdersListByPage",
    headers: {
      isToken: true,
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}

/**
 * 批量增加或修改订单
 * @param sendData
 * @returns {Promise<*>}
 */

export async function addOrUpdateOrdersList(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/orders/addOrUpdateOrdersList",
    headers: {
      isToken: true,
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}

/**
 * 批量删除订单
 * @param sendData
 * @returns {Promise<*>}
 */

export async function delOrdersList(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/orders/delOrdersList",
    headers: {
      isToken: true,
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}

/**
 * 订单核销（管理端）
 * @param sendData
 * @returns {Promise<*>}
 */

export async function editOrdersStatus(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/orders/editOrdersStatus",
    headers: {
      isToken: true,
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}

/**
 * 订单导出（管理端）
 * @param sendData
 * @returns {Promise<*>}
 */

export async function exportOrderList(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/orders/exportOrderList",
    headers: {
      isToken: true,
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}
