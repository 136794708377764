<template>
  <div id="project-list" v-loading="loading">
    <!-- 项目管理列表 -->
    <top-value
      :topSearchData="topSearchData"
      :topButtonData="topButtonData"
      @showTopButtonData="showTopButtonData"
      @topButClick="topButClick"
    ></top-value>
    <div class="content">
      <div class="table">
        <el-table
          :data="data"
          stripe
          style="width: 100%"
          height="100%"
          @row-click="handelRowClick"
        >
          <el-table-column label="序号" type="index" width="55">
          </el-table-column>
          <el-table-column
            :prop="menu.key"
            :label="menu.title"
            :width="menu.width"
            v-for="(menu, index) in tableMenu"
            show-overflow-tooltip
            :key="index"
          >
            <template slot-scope="scope">
              <span v-if="menu.isButton">
                <el-button
                  size="mini"
                 
                  :type="
                    menu.btntype
                      ? returnStatusBtn(menu.key, scope.row[menu.key])
                      : scope.row[menu.key] == null || scope.row[menu.key] == ''
                      ? '-'
                      : scope.row[menu.key]
                  "
                >
                  {{
                    menu.isChange
                      ? returnStatusText(menu.key, scope.row[menu.key])
                      : scope.row[menu.key] == null || scope.row[menu.key] == ""
                      ? "-"
                      : scope.row[menu.key]
                  }}</el-button
                >
              </span>
              <span
                v-else
                :style="{
                  color: menu.isColor
                    ? returnStatusColor(menu.key, scope.row[menu.key])
                    : '',
                }"
              >
                {{
                  menu.isChange
                    ? returnStatusText(menu.key, scope.row[menu.key])
                    : scope.row[menu.key] == null || scope.row[menu.key] == ""
                    ? "-"
                    : scope.row[menu.key]
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop=" address" label="操作" width="220">
            <template slot-scope="scope">
              <el-button
                @click.native.stop="editClick(scope.row)"
                type="primary"
                plain
                size="small"
              >
                <i class="el-icon-edit"></i> 编辑
              </el-button>
              <el-button
                @click.native.stop="deleteClick(scope.row)"
                type="danger"
                plain
                size="small"
              >
                <i class="el-icon-delete"></i> 删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageData.currentPage"
          :page-sizes="pageData.pageSizes"
          :page-size="pageData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import * as ProjectData from "./project.js";
export default {
  data() {
    return {
      loading: false,
      model: "project",
      topSearchData: ProjectData.getInitTableData().topSearchData,
      topButtonData: ProjectData.getInitTableData().topButtonData,
      tableMenu: ProjectData.getInitTableData().tableMenu,
      pageData: {
        currentPage: 1,
        total: 30,
        pageSizes: this.$PAGE_SIZES,
        pageSize: this.$PAGE_SIZE,
      },
      data: [],
    };
  },
  methods: {
    async initTableData() {
      let res = {
        pageSize: this.pageData.pageSize,
        pageNum: this.pageData.currentPage,
      };
      res = this.$total.array2json(this.topSearchData, res);

      let listData = await ProjectData.getProjectListByPage(res);
      this.data = listData.list;
      this.pageData.total = listData.total;
    },
    async topButClick(key) {
      switch (key) {
        case "查询":
          this.pageData.currentPage = 1;
          await this.initTableData();
          break;
        case "添加":
          let tag = this.$menu.returnTag(
            "编辑项目",
            { id: "" },
            "/project-edit",
            "项目列表"
          );
          this.$store.dispatch("tagsView/addView", tag);
          this.$router.push({
            path: tag.path,
            query: {
              id: "",
            },
          });
          break;

        default:
          break;
      }
    },
    /**
     * 表格按钮事件
     * @param {*} row
     * @param {*} but
     */
    editClick(row) {
      let tag = this.$menu.returnTag(
        "编辑项目",
        { id: "" },
        "/project-edit",
        "项目列表"
      );
      this.$store.dispatch("tagsView/addView", tag);
      this.$router.push({
        path: tag.path,
        query: {
          id: row.id,
        },
      });
    },

    async deleteClick(row) {
      let res = {
        idList: [],
      };
      res.idList.push(row.id);
      this.$confirm("此操作将永久删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await ProjectData.delProjectList(res);
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          await this.initTableData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 行点击事件
     * @param {*} row
     * @param {*} column
     * @param {*} event
     */

    handelRowClick(row, column, event) {
      console.log("row", row);

      let tag = this.$menu.returnTag(
        "查看行程",
        { id: "" },
        "/route-list",
        "项目列表"
      );
      this.$store.dispatch("tagsView/addView", tag);
      this.$router.push({
        path: tag.path,
        query: {
          id: row.id,
        },
      });
    },

    showTopButtonData(val) {
      this.topButtonData = val;
    },

    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.initTableData();
    },
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.initTableData();
    },
    returnStatusBtn(key, status) {
      return this.$base.getStatusNameByNum(this.model + "Btn_" + key, status);
    },
    returnStatusColor(key, status) {
      return this.$base.getStatusNameByNum(this.model + "Color_" + key, status);
    },
    returnStatusText(key, status) {
      return this.$base.getStatusNameByNum(this.model + "Text_" + key, status);
    },
  },
  mounted() {
    this.initTableData();
  },
};
</script>
<style lang="less" scoped>
#project-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > .content {
    width: 100%;
    height: 0;
    flex: 1;
    background-color: #fff;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    > .table {
      width: 100%;
      flex: 1;
      height: 0;
    }

    > .page {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
