<template>
    <div id="top-value">
        <!-- 顶部搜索组 -->
        <div class="item" v-for="item in searchList" :style="{
            width: item.type == 'dateRangeTime'
                ? '320px'
                : '200px',
        }">
            <el-input v-model="item.value" v-if="item.type == 'input'" :placeholder="'请输入' + item.title"
                :clearable="item.clearable" @keyup.enter.native="topButClick('查询')">
                <i slot="prefix" :class="item.icon"></i>
            </el-input>
            <el-date-picker style="width: 320px;" v-if="item.type == 'dateRangeTime'" v-model="item.value"
                type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                value-format="yyyy-MM-dd" :clearable="item.clearable" @change="topButClick('查询')">
            </el-date-picker>
            <el-select v-model="item.value" v-if="item.type == 'select'" :placeholder="'请选择' + item.title"
                :clearable="item.clearable" @change="topButClick('查询')">
                <el-option v-for="(it, ii) in item.options" :key="ii" :label="it.label" :value="it.value">
                </el-option>
            </el-select>
            <el-select v-model="item.value" v-if="item.type == 'selects'" multiple collapse-tags
                :placeholder="'请选择' + item.title" :clearable="item.clearable" @change="topButClick('查询')">
                <el-option v-for="(it, ii) in item.options" :key="ii" :label="it.label" :value="it.value">
                </el-option>
            </el-select>
        </div>
        <div class="but" v-for="item in buttonList" @click="topButClick(item.title)">
            <i :class="item.icon"></i>
            {{ item.title }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        topSearchData: {
            type: Array,
            default: function () {
                return [];
            },
        },
        topButtonData: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    data() {
        return {
            searchList: this.topSearchData,
            buttonList: this.topButtonData,
        };
    },
    watch: {
        topSearchData() {
            this.searchList = this.topSearchData;
        },
        topButtonData() {
            this.buttonList = this.topButtonData;
        },
        data(newValue) {
            this.$emit("showTopButtonData", this.buttonList);
        },
    },
    methods: {
        topButClick(key) {
            this.$emit("topButClick", key);
        },
    },
    mounted() {
    },
};
</script>
<style lang="less" scoped>
#top-value {
    width: 100%;
    // height: auto;
    display: flex;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.041);
    box-sizing: border-box;

    >.item {
        width: 200px;
        height: 30px;
        line-height: 30px;
        margin: 0 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        /deep/.el-input,
        /deep/.el-input__inner,
        /deep/.el-select {
            width: 100%;
            height: 30px;
            line-height: 30px;
        }

        /deep/.el-input__prefix,
        /deep/.el-input__icon,
        /deep/ .el-date-editor .el-range-separator {
            line-height: 30px;
            display: flex;
            align-items: center;
        }

        /deep/.el-input__inner {
            border: 1px solid #e9e9e9;
            background-color: #ececec91;
            border-radius: 4px;
            // color: #fff;
        }

        /deep/ .el-date-editor .el-range-input {
            background-color: #f1f1f100;
        }
    }

    >.but {
        width: auto;
        height: auto;
        padding: 6px 23px;
        cursor: pointer;
        transition: 0.3s;
        font-size: 14px;
        margin: 0 10px;
        border-radius: 5px;
        background-color: #4099FF;
        color: #fff;
    }
}
</style>