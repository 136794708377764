import Vue from "vue";
/**
 * 项目列表界面基础数据
 * @returns
 */
export function getInitTableData() {
  let data = {
    topSearchData: [
      {
        title: "标题名称",
        type: "input",
        value: "",
        key: "title",
        clearable: true,
      },
    ],
    topButtonData: [
      {
        title: "查询",
        icon: "el-icon-search",
      },
      {
        title: "添加",
        icon: "el-icon-plus",
      },
    ],
    tableMenu: [
      {
        key: "title",
        title: "标题名称",
        isColor: false,
        isChange: false,
        width: "",
      },
      
      {
        key: "content",
        title: "行程内容",
        isColor: false,
        isChange: false,
        showDetail: true,
        width: "",
      },
      {
        key: "orderNum",
        title: "排序",
        isColor: false,
        isChange: false,
        width: "",
      },
    
    ],
  };
  return data;
}

/**
 * 初始化项目编辑界面基础数据
 * @returns
 */
export function initEditData() {
  let data = [
    {
      title: "标题名称",
      key: "title",
      type: "input",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "行程内容",
      key: "content",
      type: "richtext",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    
    
    {
      title: "行程排序",
      key: "orderNum",
      type: "input-number",
      value: "",
      width: "100%",
      isNotNull: true,
    },
  ];
  return data;
}

/**
 * 获取行程列表
 * @param sendData
 * @returns {Promise<*>}
 */

export async function getItineraryListByPage(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/itinerary/getItineraryListByPage",
    headers: {
      isToken: true
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}

/**
 * 批量增加或修改行程
 * @param sendData
 * @returns {Promise<*>}
 */

export async function addOrUpdateItineraryList(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/itinerary/addOrUpdateItineraryList",
    headers: {
      isToken: true
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}

/**
 * 批量删除行程
 * @param sendData
 * @returns {Promise<*>}
 */

export async function delItineraryList(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/itinerary/delItineraryList",
    headers: {
      isToken: true
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}




