<template>
  <div id="project-edit" v-loading="loading">
    <!-- 编辑项目界面 -->
    <div class="buts">
      <div
        class="but"
        v-for="(but, index) in butList"
        :key="index"
        @click="handleBut(but)"
      >
        <i :class="but.icon"></i>{{ but.text }}
      </div>
    </div>
    <div class="content customScrollBar">
      <edit-value
        :editValueData="data"
        @showEditValueData="showEditValueData"
        @openUeditor="openUeditor"
        @handleAvatarSuccess="handleAvatarSuccess"
        @handleError="handleError"
        @beforeAvatarUpload="beforeAvatarUpload"
        @deleteImg="deleteImg"
      ></edit-value>
    </div>
  </div>
</template>
<script>
import * as personData from "./person.js";

export default {
  data() {
    return {
      id: "",
      loading: false,
      butList: [
        { text: "取消", icon: "el-icon-close" },
        { text: "保存", icon: "el-icon-check" },
      ],
      data: personData.initEditData(),
    };
  },
  methods: {
    //获取用户信息
    async getUserInfo() {
      let res = await personData.getUserInfo({});
      res.nickName=  res.nickName?res.nickName : ""
      res.titleImage=  res.titleImage?res.titleImage : ""
      console.log("获取用户信息", res);
      let temp = {};
      this.data = this.$total.json2array(this.data, res);
      console.log("temp", temp,this.data);
      // this.data = res.result;
    },
    async handleBut(but) {
      switch (but.text) {
        case "保存":
          let res = {
            id: this.id,
          };
          let flag = this.$total.judegArrayNull(this.data);
          console.log("flag",flag,this.data);

          if (flag != "ok") {
            this.$message.error(flag + "不能为空");
            return;
          }
          res = this.$total.array2json(this.data, res);
          console.log("res",res);
          // let dataList = [];
          // dataList.push(res);

          await personData.updateAdmin(res);
          this.$message.success("保存成功");
          this.$router.push({ path: "/edit-person" });
          break;
        case "取消":
          this.$store.dispatch("tagsView/delView", this.$router.currentRoute);
          this.$router.go(-1);
          break;

        default:
          break;
      }
    },
    handleAvatarSuccess(response, file, fileList) {
      if (response.code == "200") {
        switch (response.result.title) {
          case "用户头像":
            // 用户头像
            this.data.forEach((element) => {
              if (element.key == "titleImage") {
                let url = process.env.VUE_APP_BASE_API + response.result.path;
                element.value = [url];
              }
            });
            break;

          default:
            break;
        }
      }
      this.loading = false;
    },
    handleError() {
      this.$message({
        message: "文件上传失败!",
        type: "warning",
      });
      this.loading = false;
    },
    beforeAvatarUpload(file) {
      console.log(file);
      if (
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png"
      ) {
        this.loading = true;
      } else {
        this.$message({
          message: "仅支持上传JPG/JPEG/PNG格式!",
          type: "warning",
        });
        return false;
      }
    },
    //删除照片
    deleteImg(item, index) {
      console.log("item", item);
      console.log("index", index);
      item.value.splice(index, 1);
    },
    /**
     * 打开富文本编辑器
     * @param {*} item
     */
    openUeditor(item) {
      console.log(item);
      setTimeout(() => {
        this.tempKey = item.key;
        this.ueditorData.title = "编辑" + item.title;
        this.ueditorData.content = item.value;
        this.ueditorData.flag = true;
      }, 500);
    },
    makeSureUeditor(val) {
      this.data.forEach((element) => {
        if (element.key == this.tempKey) {
          element.value = val.contentHtml;
          return;
        }
      });
      this.cancelDialog();
    },
    showEditValueData(val) {
      this.data = val;
    },
    cancelDialog() {
      this.ueditorData.flag = false;
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
</script>
<style lang="less" scoped>
#project-edit {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;

  > .buts {
    width: 100%;
    // height: 40px;
    display: flex;

    > .but {
      width: auto;
      height: auto;
      padding: 6px 23px;
      cursor: pointer;
      transition: 0.3s;
      font-size: 14px;
      margin: 0 10px;
      border-radius: 5px;
      background-color: #4099ff;
      color: #fff;

      > i {
        margin-right: 5px;
      }
    }
  }

  > .content {
    width: 100%;
    height: 0;
    flex: 1;
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    overflow-y: auto;
    align-content: flex-start;

    > .line {
      // width: 100%;
      height: auto;
      display: flex;
      margin-top: 10px;

      > .title {
        width: 220px;
        // height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;

        margin-right: 10px;
        margin-top: 8px;
        font-size: 15px;

        > .notnull {
          color: #f00;
          margin-right: 5px;
          margin-top: 3px;
        }
      }

      > .value {
        width: 0;
        flex: 1;
        height: auto;
        min-height: 40px;
        display: flex;
        align-items: flex-start;

        /deep/.el-input__inner,
        /deep/.el-textarea__inner {
          border: none;
          background-color: #f6f9ff;
          border-radius: 2px;
        }

        /deep/.el-select,
        /deep/.el-date-editor.el-input,
        /deep/ .el-date-editor.el-input__inner {
          width: 100%;
        }

        /deep/.el-radio-group {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 5px;
        }

        > .richtext {
          width: 100%;
          margin-top: 8px;
          font-size: 14px;
          color: #4099ff;
          cursor: pointer;
        }

        > .imgs {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          padding: 10px;
          box-sizing: border-box;

          .img {
            width: 150px;
            height: 150px;
            background-color: #f6f9ff;
            margin: 6px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;

            > img {
              width: 100%;
            }

            i {
              font-size: 20px;
            }

            > .mb {
              width: 100%;
              position: absolute;
              bottom: 0;
              height: 40px;
              background-color: #2e2e2e2c;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
</style>
