<template>
  <div id="project-edit" v-loading="loading">
    <!-- 编辑项目界面 -->
    <div class="buts">
      <div
        class="but"
        v-for="(but, index) in butList"
        @click="handleBut(but)"
        :key="index"
      >
        <i :class="but.icon"></i>{{ but.text }}
      </div>
    </div>
    <div class="content customScrollBar">
      <edit-value
        :editValueData="data"
        @showEditValueData="showEditValueData"
        @openUeditor="openUeditor"
        @handleAvatarSuccess="handleAvatarSuccess"
        @handleError="handleError"
        @beforeAvatarUpload="beforeAvatarUpload"
        @tagfocus="tagfocus"
        @deleteImg="deleteImg"
      ></edit-value>
    </div>

    <ueditor-dialog
      v-if="ueditorData.flag"
      :width="ueditorData.width"
      :title="ueditorData.title"
      :content="ueditorData.content"
      @cancelDialog="cancelDialog"
      @makeSure="makeSureUeditor"
    >
    </ueditor-dialog>
  </div>
</template>
<script>
// import { forEach } from "core-js/core/array";
import * as routeData from "./route.js";
export default {
  data() {
    return {
      id: "",
      projectId:"",
      loading: false,
      butList: [
        { text: "取消", icon: "el-icon-close" },
        { text: "保存", icon: "el-icon-check" },
      ],
      data: routeData.initEditData(),

      tempKey: "",
      ueditorData: {
        flag: false,
        width: "60%",
        title: "编辑题干",
        content: "",
      },
    };
  },
  methods: {
    tagfocus(key) {},

    async handleBut(but) {
      switch (but.text) {
        case "保存":
          //增加项目
          let res = {
            id: this.id,
            projectId: this.projectId,
            
          };
          res = this.$total.array2json(this.data, res);
          let flag = this.$total.judegArrayNull(this.data);
          if (flag != "ok") {
            this.$message.error(flag + "不能为空");
            return;
          }
          let dataList = [];
          dataList.push(res);
         
          await routeData.addOrUpdateItineraryList({ dataList: dataList });
          this.$message.success("保存成功");
          this.$router.push({ path: "/route-list" });
          break;

        case "取消":
          //取消
          this.$store.dispatch("tagsView/delView", this.$router.currentRoute);
          this.$router.go(-1);
          break;

        default:
          break;
      }
    },
    handleAvatarSuccess(response, file, fileList) {
      
    },
    handleError() {
      this.$message({
        message: "文件上传失败!",
        type: "warning",
      });
      this.loading = false;
    },
    beforeAvatarUpload(file) {
      console.log(file);
      if (
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png"
      ) {
        this.loading = true;
      } else {
        this.$message({
          message: "仅支持上传JPG/JPEG/PNG格式!",
          type: "warning",
        });
        return false;
      }
    },
    //删除照片
    deleteImg(item, index) {
    
      item.value.splice(index, 1);
    },
    /**
     * 打开富文本编辑器
     * @param {*} item
     */
    openUeditor(item) {
      console.log(item);
      setTimeout(() => {
        this.tempKey = item.key;
        this.ueditorData.title = "编辑" + item.title;
        this.ueditorData.content = item.value;
        this.ueditorData.flag = true;
      }, 500);
    },
    makeSureUeditor(val) {
      this.data.forEach((element) => {
        if (element.key == this.tempKey) {
          element.value = val.contentHtml;
          return;
        }
      });
      this.cancelDialog();
    },
    showEditValueData(val) {
      this.data = val;
    },
    cancelDialog() {
      this.ueditorData.flag = false;
    },
  },
  async mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : "";
    this.projectId = this.$route.query.projectId ? this.$route.query.projectId : "";
    
    //如果存在id则为修改
    //如果不存在id则为新增
  
    if (this.id) {
      let result = await routeData.getItineraryListByPage({
        id: this.id,
      });
      let resultJ = result.list[0];
      this.$total.json2array(this.data, resultJ);
    }
  },
};
</script>
<style lang="less" scoped>
#project-edit {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;

  > .buts {
    width: 100%;
    // height: 40px;
    display: flex;

    > .but {
      width: auto;
      height: auto;
      padding: 6px 23px;
      cursor: pointer;
      transition: 0.3s;
      font-size: 14px;
      margin: 0 10px;
      border-radius: 5px;
      background-color: #4099ff;
      color: #fff;

      > i {
        margin-right: 5px;
      }
    }
  }

  > .content {
    width: 100%;
    height: 0;
    flex: 1;
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    overflow-y: auto;
    align-content: flex-start;
  }
}
</style>
