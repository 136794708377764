import Vue from "vue";
import VueRouter from "vue-router";
import { Message } from "element-ui";
import test from "../views/test/test.vue";
import Login from "../views/login.vue";

import Main from "../views/main.vue";

import ProjectList from "../views/project/list.vue";
import ProjectEdit from "../views/project/edit.vue";

import TagList from "../views/tags/list.vue";
import TagEdit from "../views/tags/edit.vue";

import RouteList from "../views/project/rotelist.vue";
import RouteEdit from "../views/project/routeEdit.vue";

import OrderList from "../views/order/list.vue";
import OrderEdit from "../views/order/edit.vue";

import PersonEdit from "../views/person/person.vue";
import AccountEdit from "../views/person/accountEdit.vue";



Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/main",
    name: "Main",
    component: Main,
    children: [
      {
        path: "/project-list",
        name: "ProjectList",
        component: ProjectList,
      },
      {
        path: "/project-edit",
        name: "ProjectEdit",
        component: ProjectEdit,
      },
      {
        path: "/tag-list",
        name: "TagList",
        component: TagList,
      },
      {
        path: "/tag-edit",
        name: "TagEdit",
        component: TagEdit,
      },
      {
        path: "/route-list",
        name: "RouteList",
        component: RouteList,
      },
      {
        path: "/route-edit",
        name: "RouteEdit",
        component: RouteEdit,
      },
      {
        path: "/order-list",
        name: "OrderList",
        component: OrderList,
      },
      {
        path: "/order-edit",
        name: "OrderEdit",
        component: OrderEdit,
      },
      {
        path: "/edit-person",
        name: "PersonEdit",
        component: PersonEdit,
      },
      {
        path: "/edit-account",
        name: "AccountEdit",
        component: AccountEdit,
      },
    ],
  },
  {
    path: "/test",
    name: "test",
    component: test,
  },
];

const router = new VueRouter({
  routes,
});

/**
 * 导航守卫
 */
router.beforeEach(async (to, from, next) => {
  // if (to.path != '/' && to.path.indexOf('test') < 0) {
  //     try {
  //         let route_now = to.path
  //         let data = await getAuthorityPath()
  //         let flag = false
  //         console.log("当前路由", route_now);
  //         data.forEach(element => {
  //             if (route_now == element) {
  //                 flag = true
  //             }
  //         });
  //         if (flag) {
  //             // 允许跳转
  //             next()
  //         } else {
  //             Message({
  //                 message: "您没有权限,请联系管理员!",
  //                 type: 'error'
  //             })
  //         }
  //     } catch (error) {

  //     }
  // } else {
  next();
  // }
});

//重复路由
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
