<template>
  <div id="main">
    <!-- 首页 -->
    <div class="left">
      <span class="open_menu" @click="openCollapse">
        <i :class="!isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
        <span v-if="isCollapse"></span>
        <span v-else>大左旅游小程序后台</span>
      </span>
      <el-menu
        :default-active="defaultActive"
        class="el-menu-vertical-demo"
        background-color="#545c64"
        text-color="#fff"
        :collapse="isCollapse"
        active-text-color="#ffd04b"
      >
        <template v-for="(menu, index) in menuList">
          <el-submenu :index="menu.name" :key="index" v-if="menu.children">
            <template slot="title">
              <i :class="menu.icon"></i>
              <span>{{ menu.name }}</span>
            </template>
            <el-menu-item
              :index="submenu.name"
              v-for="(submenu, ii) in menu.children"
              :key="ii"
              @click="addTag(submenu)"
              >{{ submenu.name }}</el-menu-item
            >
          </el-submenu>
          <template v-else>
            <el-menu-item :index="menu.name" @click="addTag(menu)" :key="index">
              <i :class="menu.icon"></i>
              <span>{{ menu.name }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </div>
    <div class="right">
      <div class="top">
        <div class="name">大左旅游小程序后台</div>
        <div class="settings">
          <el-dropdown trigger="hover" placement="bottom-start">
            <span class="el-dropdown-link">
              <img :src="user.img" alt="" /><i
                class="el-icon-arrow-down el-icon--right"
              ></i>
            </span>
            <el-dropdown-menu slot="dropdown" >
              <el-dropdown-item style="width: 100px">
                <span class="admin-name">{{ user.name }}</span>
              </el-dropdown-item>
              <el-dropdown-item style="width: 100px">
                <span class="admin-name" @click="quit">退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="tips">
        <router-link
          v-for="tag in visitedViews"
          ref="tag"
          :key="tag.path"
          :class="isActive(tag) ? 'active' : ''"
          :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
          tag="span"
          class="tags-view-item"
          :style="activeStyle(tag)"
          @click.middle.native="!isAffix(tag) ? closeSelectedTag(tag) : ''"
        >
          {{ tag.title }}
          <span
            v-if="!isAffix(tag)"
            class="el-icon-close"
            @click.prevent.stop="closeSelectedTag(tag)"
          />
        </router-link>
      </div>
      <div class="content">
        <keep-alive :include="cachedViews">
          <router-view />
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import * as MenuData from "./menu.js";
import * as personData from "./person/person.js";

export default {
  data() {
    return {
      menuList: MenuData.initMenu(),
      defaultActive: "项目列表",
      isCollapse: false,
      visible: false,
      user: {
        img: "../assets/images/user.png",
        name: "威尔史密斯",
      },
      activeTag: {},
    };
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
  },
  methods: {
    addTag(tag) {
      this.$store.dispatch("tagsView/addView", tag);
      this.$router.push(tag.path);
    },
    //   判断是否为当前高亮显示的菜单
    isActive(route) {
      return route.path === this.$route.path;
    },
    // 高亮显示的样式
    activeStyle(tag) {
      if (!this.isActive(tag)) return {};
      this.defaultActive = tag.meta.activeMenu;
      this.activeTag = tag;
      return {
        "background-color": "#409EFF",
        "border-color": "#409EFF",
      };
    },
    // 跳转到最后一个菜单上
    toLastView(visitedViews, view) {
      const latestView = visitedViews.slice(-1)[0];
      console.log("跳转到最后一个菜单", latestView);
      if (latestView) {
        this.$router.push(latestView.fullPath);
      } else {
        // now the default is to redirect to the home page if there is no tags-view,
        // you can adjust it according to your needs.
        if (view.name === "Dashboard") {
          // to reload home page
          this.$router.replace({ path: "/redirect" + view.fullPath });
        } else {
          this.$router.push("/main");
        }
      }
    },
    // 判断是否显示关闭按钮
    isAffix(tag) {
      return tag.meta && tag.meta.affix;
    },
    isFirstView() {
      try {
        return (
          this.selectedTag.fullPath === "/main" ||
          this.selectedTag.fullPath === this.visitedViews[1].fullPath
        );
      } catch (err) {
        return false;
      }
    },
    isLastView() {
      try {
        return (
          this.selectedTag.fullPath ===
          this.visitedViews[this.visitedViews.length - 1].fullPath
        );
      } catch (err) {
        return false;
      }
    },
    // 关闭选择标签页
    closeSelectedTag(view) {
      this.closePage(view).then(({ visitedViews }) => {
        if (this.isActive(view)) {
          this.toLastView(visitedViews, view);
        }
      });
    },
    // 关闭指定tab页签
    closePage(obj) {
      if (obj === undefined) {
        return this.$store
          .dispatch("tagsView/delView", router.currentRoute)
          .then(({ visitedViews }) => {
            const latestView = visitedViews.slice(-1)[0];
            if (latestView) {
              return router.push(latestView.fullPath);
            }
            return this.$router.push("/");
          });
      }
      return this.$store.dispatch("tagsView/delView", obj);
    },
    openCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    async getUserInfo() {
      let res = await personData.getUserInfo({});
      res.nickName = res.nickName ? res.nickName : "";
      res.titleImage = res.titleImage ? res.titleImage : "";
      this.user.img = res.titleImage[0] ? res.titleImage[0] : "";
      this.user.name = res.name ? res.name : "";
    },
    //上传头像
    editImage() {},
    //设置账号密码
    setInfo() {
      let tag = this.$menu.returnTag(
        "编辑标签",
        { id: "" },
        "/edit-person",
        "标签列表"
      );
      this.$store.dispatch("tagsView/addView", tag);
      this.$router.push({
        path: tag.path,
        query: {
          id: row.id,
        },
      });
    },
    //退出登录
    quit() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
</script>
<style lang="less" scoped>
#main {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  > .left {
    height: 100%;
    background-color: #545c64;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;

    > .open_menu {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ebebeb;
      cursor: pointer;
    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
      width: 200px;
    }

    /deep/.el-menu {
      border: none;
    }

    /deep/.el-menu-item,
    /deep/.el-submenu__title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #fff;

      .iconfont {
        margin-right: 5px;
        color: #fff;
        font-size: 18px;
      }
    }
  }

  > .right {
    width: 0;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    > .top {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      align-items: center;
      padding-right: 50px;
      display: flex;
      justify-content: space-between;
      > .name {
        font-size: 18px;
        margin-left: 20px;
      }
      .settings {
        width: 100px;
        border-radius: 10px;
        height: 36px;
        background-color: #4099ff;

        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        /deep/.el-dropdown-link {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 10px;
          }
          span {
            font-size: 14px;
            color: #fff;
          }
          i {
            color: #fff;
          }
          .admin-name {
            width: 220px;
            height: 40px;
          }
        }
      }
    }

    > .tips {
      width: 100%;
      height: 40px;
      padding: 3px;
      display: flex;
      border-bottom: 1px solid #ebebeb;

      .tags-view-item {
        display: inline-block;
        position: relative;
        cursor: pointer;
        height: 26px;
        line-height: 26px;
        border: 1px solid #d8dce5;
        color: #495060;
        background: #fff;
        padding: 0 8px;
        font-size: 12px;
        margin-left: 5px;
        margin-top: 4px;

        &:first-of-type {
          margin-left: 15px;
        }

        &:last-of-type {
          margin-right: 15px;
        }

        &.active {
          background-color: #42b983;
          color: #fff;
          border-color: #42b983;

          &::before {
            content: "";
            background: #fff;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            position: relative;
            margin-right: 2px;
          }
        }

        .el-icon-close {
          width: 16px;
          height: 16px;
          vertical-align: 2px;
          border-radius: 50%;
          text-align: center;
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transform-origin: 100% 50%;

          &:before {
            transform: scale(0.6);
            display: inline-block;
            vertical-align: -3px;
          }

          &:hover {
            background-color: #b4bccc;
            color: #fff;
          }
        }
      }
    }

    > .content {
      width: 100%;
      height: 0;
      flex: 1;
      background-color: #f9fbff;
      padding: 20px;
      box-sizing: border-box;
    }
  }
  /deep/ .el-dropdown-menu__item {
    width: 220px;
    font-size: 14px;
    padding: 0 20px;
    cursor: pointer;
  }
}
</style>
