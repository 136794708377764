import Vue from "vue";

/**
 * 项目列表界面基础数据
 * @returns
 */
export function getInitTableData() {
  let data = {
    topSearchData: [
      {
        title: "标签名称",
        type: "input",
        value: "",
        key: "userName",
        clearable: true,
      },
    ],
    topButtonData: [
      {
        title: "查询",
        icon: "el-icon-search",
      },
      {
        title: "添加",
        icon: "el-icon-plus",
      },
    ],
    tableMenu: [
      {
        key: "userName",
        title: "用户名",
        isColor: false,
        isChange: false,
        width: "",
      },
      
    ],
   
  };
  return data;
}

/**
 * 初始化项目编辑界面基础数据
 * @returns
 */
export function initEditData() {
  let data = [
    {
      title: "昵称",
      key: "nickName",
      type: "input",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "用户名",
      key: "userName",
      type: "input",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "手机号",
      key: "phone",
      type: "input",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    // {
    //     title: "密码",
    //     key: "password",
    //     type: "input",
    //     value: "",
    //     width: "70%",
    //     isNotNull: true,
    //   },
      {
        title: "头像",
        key: "titleImage",
        type: "imgArray",
        value: [],
        width: "70%",
        isNotNull: true,
        uploadData: {
          action: process.env.VUE_APP_BASE_API + "/tourism/file/uploadFile",
          width: "480px",
          height: "270px",
          headers: {
            Authorization: "Training " + localStorage.getItem("isToken"),
          },
          data: {
            key: "imgs",
            title: "用户头像",
          },
        },
      },
  ];
  return data;
}

/**
 * 初始化项目编辑界面基础数据
 * @returns
 */
export function initAccountData() {
  let data = [
    {
      title: "原密码",
      key: "password",
      type: "password",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "新密码",
      key: "newPassword",
      type: "password",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "确认密码",
      key: "confirmPassword",
      type: "password",
      value: "",
      width: "70%",
      isNotNull: true,
    },
   
  ];
  return data;
}





  /**
 * 获取用户信息
 * @param sendData
 * @returns {Promise<*>}
 */

export async function getUserInfo(sendData) {
    return await Vue.prototype.$request({
      url: "/tourism/user/getAdminInfo",
      headers: {
        isToken: true,
      },
      method: "post",
      timeout: 20000,
      data: sendData,
    });
  }

  
  /**
 * 修改管理员个人信息
 * @param sendData
 * @returns {Promise<*>}
 */

export async function updateAdmin(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/user/updateAdmin",
    headers: {
      isToken: true,
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}