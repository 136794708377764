<template>
  <div id="test">
    <div id="test_echart">
      <Ueditor @ready="editorReady" />
    </div>
    <el-select v-model="value1" multiple placeholder="请选择">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
      
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import Ueditor from "@/components/Ueditor";
export default {
  components: {
    Ueditor,
  },
  name: "ueditor_dialog",
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value1: [
       "选项1"
      ],
      content: "",
      richEditor: {
        dialogVisible: false,
        object: {
          id: null,
          questionType: 4,
          gradeLevel: null,
          subjectId: null,
          title: "",
          items: [],
          analyze: "",
          correct: "",
          score: "",
          difficult: 0,
        },
        parameterName: "title",
        instance: null,
      },
    };
  },
  methods: {
    editorReady(instance) {
      this.richEditor.instance = instance;
      let currentContent =
        this.richEditor.object[this.richEditor.parameterName];
      this.richEditor.instance.setContent(currentContent);
      // 光标定位到Ueditor
      this.richEditor.instance.focus(true);
    },
  },
  async mounted() {
    setTimeout(() => {
      this.richEditor.instance.setContent(this.content);
      // 光标定位到Ueditor
      this.richEditor.instance.focus(true);
    }, 100);
  },
};
</script>

<style lang="less" scoped>
#test {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  #test_echart {
    width: 1000px;
    height: 900px;
    // background-color: #4099FF;
    display: flex;
    align-items: center;
    justify-content: center;

    .login-code-img {
      height: 38px;
    }
  }
}
</style>
