import Vue from "vue";
/**
 * 项目列表界面基础数据
 * @returns
 */
export function getInitTableData() {
  let data = {
    topSearchData: [
      {
        title: "项目名称",
        type: "input",
        value: "",
        key: "name",
        clearable: true,
      },
    ],
    topButtonData: [
      {
        title: "查询",
        icon: "el-icon-search",
      },
      {
        title: "添加",
        icon: "el-icon-plus",
      },
    ],
    tableMenu: [
      {
        key: "name",
        title: "项目名称",
        isColor: false,
        isChange: false,
        isButton:false,
        width: "",
      },
      {
        key: "price",
        title: "标价",
        isColor: false,
        isChange: false,
        isButton:false,
        width: "80",
      },
      {
        key: "preferentialPrice",
        title: "实际价格",
        isColor: false,
        isChange: false,
        isButton:false,
        width: "80",
      },
      {
        key: "tag",
        title: "标签",
        isColor: false,
        isChange: false,
        isButton:false,
        width: "180",
      },
      {
        key: "browse",
        title: "浏览",
        isColor: false,
        isChange: false,
        isButton:false,
        width: "80",
      },
      {
        key: "describe",
        title: "描述",
        isColor: false,
        isChange: false,
        isButton:false,
        width: "",
      },
      {
        key: "status",
        title: "是否上架",
        isColor: true,
        isChange: true,
        btntype:true,
        width: "",
        isButton:true
      },
    ],
  };
  return data;
}

/**
 * 初始化项目编辑界面基础数据
 * @returns
 */
export function initEditData() {
  let data = [
    {
      title: "项目名称",
      key: "name",
      type: "input",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "项目标价",
      key: "price",
      type: "input",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "项目实际价格",
      key: "preferentialPrice",
      type: "input",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "景点封面",
      key: "titleImage",
      type: "imgArray",
      value: [],
      width: "70%",
      isNotNull: true,
      uploadData: {
        action: process.env.VUE_APP_BASE_API + "/tourism/file/uploadFile",
        width: "480px",
        height: "270px",
        headers: {
          Authorization: "Training " + localStorage.getItem("isToken"),
        },
        data: {
          key: "imgs",
          title: "景点封面",
        },
      },
    },
    {
      title: "景点轮播图",
      key: "images",
      type: "imgArray",
      value: [],
      width: "70%",
      isNotNull: true,
      uploadData: {
        action: process.env.VUE_APP_BASE_API + "/tourism/file/uploadFile",
        width: "480px",
        height: "270px",
        headers: {
          Authorization: "Training " + localStorage.getItem("isToken"),
        },
        data: {
          key: "images",
          title: "景点轮播图",
        },
      },
    },
    {
      title: "项目标签",
      key: "tagsId",
      type: "tags",
      value: [],
      width: "70%",
      options: [],
      isNotNull: true,
    },
    {
      title: "产品特色",
      key: "productFeatures",
      type: "richtext",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "项目描述",
      key: "description",
      type: "textarea",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "温馨提示",
      key: "warmReminder",
      type: "richtext",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "游客点评",
      key: "commentOn",
      type: "richtext",
      value: "",
      width: "70%",
      isNotNull: true,
    },
    {
      title: "是否下架",
      key: "status",
      type: "radio",
      value: "1",
      width: "70%",
      isNotNull: true,
      options: [
        { label: "上架", value: "1" },
        { label: "下架", value: "0" },
      ],
    },
  ];
  return data;
}

/**
 * 获取项目列表
 * @param sendData
 * @returns {Promise<*>}
 */

export async function getProjectListByPage(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/project/getProjectListAdmin",
    headers: {
      isToken: true,
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}

/**
 * 批量增加或修改项目
 * @param sendData
 * @returns {Promise<*>}
 */

export async function addOrUpdateProjectList(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/project/addOrUpdateProjectList",
    headers: {
      isToken: true,
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}

/**
 * 批量删除项目景点
 * @param sendData
 * @returns {Promise<*>}
 */

export async function delProjectList(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/project/delProjectList",
    headers: {
      isToken: true,
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}

/**
 *获取标签列表
 * @param sendData
 * @returns {Promise<*>}
 */

export async function getTagsListByPage(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/tags/getTagsListByPage",
    headers: {
      isToken: true,
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}

/**
 *获取项目下标签列表
 * @param sendData
 * @returns {Promise<*>}
 */

export async function getTagsListByProject(sendData) {
  return await Vue.prototype.$request({
    url: "/tourism/tags/getTagsListByProject",
    headers: {
      isToken: true,
    },
    method: "post",
    timeout: 20000,
    data: sendData,
  });
}
