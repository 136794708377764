
/**
 * 将状态转换为文字
 * @param {*} model
 * @param {*} status
 * @returns
 */
export function getStatusNameByNum(model, status) {
  let str = "";
  switch (model) {
    case "projectText_status":
      // 项目列表--是否上架
      switch (status) {
        case "1":
          str = "上架";
          break;
        case "0":
          str = "下架";
          break;

        default:
          break;
      }
      break;

    case "projectColor_status":
      // 项目列表--是否上架
      switch (status) {
        case "1":
          str = "#409EFF";
          break;
        case "0":
          str = "#F56C6C";
          break;

        default:
          break;
      }
      break;
    case "projectBtn_status":
      // 项目列表--是否上架
      switch (status) {
        case "1":
          str = "primary";
          break;
        case "0":
          str = "danger";
          break;

        default:
          break;
      }
      break;
    case "OrderText_isPickup":
      // 订单模块--是否接机
      switch (status) {
        case true:
          str = "是";
          break;
        case false:
          str = "否";
          break;

        default:
          break;
      }
      break;
    case "OrderColor_isPickup":
      //订单模块--是否接机
      switch (status) {
        case true:
          str = "#409EFF";
          break;
        case false:
          str = "#F56C6C";
          break;

        default:
          break;
      }
      break;

    default:
      break;
  }
  return str;
}
