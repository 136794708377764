import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
// import * as echarts from 'echarts';
import store from "@/store";

Vue.prototype.$store = store
import "./assets/css/base.css"

import "./assets/iconfont/iconfont.css" // 阿里巴巴矢量图标库
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(VueAxios, axios);

import request from "./utils/request.js"
import formatTime from "./utils/formatTime.js"
import * as total from "./utils/total.js"
import * as base from "./utils/base.js"
import * as menu from './views/menu.js'

Vue.prototype.$request = request
Vue.prototype.$formatTime = formatTime
Vue.prototype.$total = total
    // Vue.prototype.$echarts = echarts
Vue.prototype.$base = base
Vue.prototype.$menu = menu


Vue.prototype.$PAGE_SIZES = [10, 20, 50, 100] // 分页
Vue.prototype.$PAGE_SIZE = 10 // 分页

//全局组件
import "@/components/globalRegisterComponents";


import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer);
Viewer.setDefaults({
    Options: {
        "inline": true,
        "button": true,
        "navbar": true,
        "title": false,
        "toolbar": true,
        "tooltip": true,
        "movable": true,
        "zoomable": true,
        "rotatable": true,
        "scalable": true,
        "transition": true,
        "fullscreen": true,
        "keyboard": true,
        "url": "data-source"
    }
});

// 自定义拖拽组件
import VueDND from 'awe-dnd'
Vue.use(VueDND)

// 一键复制
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')