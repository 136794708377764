import Vue from "vue";
/**
 * 将数字转换成大写字母
 * @param {*} num
 */
export function numberToLetter(num) {
  return String.fromCharCode(num + 64);
}

/**
 * 数组转对象传值
 * @param {*} array
 * @param {*} json
 */
export function array2json(array, json) {
  array.forEach((element) => {
    Vue.set(json, element.key, element.value);
  });
  return json;
}

/**
 * 对象转数组传值
 * @param {*} array
 * @param {*} json
 */
export function json2array(array, json) {
  array.forEach((element) => {
    element.value = json[element.key];
  });
  return array;
}

/**
 * 对象数组去重
 * @param {*} array  对象数组
 * @param {*} object  要添加的对象
 */
export function deWeight(array, object) {
  let data = array;
  data.push(JSON.parse(JSON.stringify(object)));
  let set = new Set(data.map(JSON.stringify));
  array = Array.from(set).map(JSON.parse);
  return array;
}

/**
 * 判断当前字符串是否是数字
 * @param {*} str 要判断的字符串
 * 返回true 为数字型,包含小数
 * 返回false 为字符串型
 */
export function judgeStringIsNumber(str) {
  return !isNaN(str);
}

/**
 * 数字校验
 * @param {*} number
 */
export function formatMathDecimal(number) {
  var numReg = /^[\+\-]?\d*?\.?\d*?$|^([^0][0-9]+|0)$/;
  var numRe = new RegExp(numReg);
  if (!numRe.test(number)) {
    return false;
  }
  return true;
}

/**
 * 校验正整数
 * @param {*} number
 */
export function formatMathNumber(number) {
  var reg = /^[1-9]\d*$/;
  var numRe = new RegExp(reg);
  if (!numRe.test(number)) {
    return false;
  }
  return true;
}

/**
 * 仅输入字母与数字校验
 * @param {*} number
 */
export function formatLetterAndNumber(str) {
  var numReg = /^[a-zA-Z0-9_]+$/;
  var numRe = new RegExp(numReg);
  if (!numRe.test(str)) {
    return false;
  }
  return true;
}

/**
 * 判断数组中不可为空的数据是否为空
 * @param {*} array
 */
export function judegArrayNull(array) {
  try {
    array.forEach((element) => {
      if (element.isNotNull) {
        if (typeof element.value == "string" && element.value == "") {
          throw element.title;
        }
        if (Array.isArray(element.value) && element.value == []) {
          throw element.title;
        }
      }
    });
    return "ok";
  } catch (msg) {
    return msg;
  }
}
