<template>
  <div id="ueditor_dialog" @click="cancelDialog">
    <!-- 题干及答案等的编辑器弹出框 -->
    <div class="content" :style="{ width: width }" @click.stop="clickNull">
      <div class="title">{{ title }}</div>
      <div class="colse" @click="cancelDialog">
        <i class="el-icon-close"></i>
      </div>
      <div class="con">
        <div class="cc">
          <Ueditor @ready="editorReady" />
        </div>
      </div>
      <div class="buts">
        <div class="but" @click="makeSure()">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
import Ueditor from "@/components/Ueditor";
export default {
  components: {
    Ueditor,
  },
  name: "ueditor_dialog",
  props: {
    width: {
      type: String,
      default: "40%",
    },
    title: {
      type: String,
      default: "编辑题干",
    },
    content: {
      // 编辑器内容
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isActive: this.active,
      richEditor: {
        dialogVisible: false,
        object: {
          id: null,
          questionType: 4,
          gradeLevel: null,
          subjectId: null,
          title: "",
          items: [],
          analyze: "",
          correct: "",
          score: "",
          difficult: 0,
        },
        parameterName: "title",
        instance: null,
      },
    };
  },
  watch: {
    active() {
      this.isActive = this.active;
    },
    isActive(newValue) {
      this.$emit("showActive", this.isActive);
    },
  },
  methods: {
    editorReady(instance) {
      this.richEditor.instance = instance;
      let currentContent = this.richEditor.object[
        this.richEditor.parameterName
      ];
      this.richEditor.instance.setContent(currentContent);
      // 光标定位到Ueditor
      this.richEditor.instance.focus(true);
    },
    cancelDialog() {
      this.$emit("cancelDialog");
    },
    clickNull() { },
    makeSure() {
      let content = this.richEditor.instance.getContent();
      let text = this.richEditor.instance.getContentTxt();
      this.$emit("makeSure", { contentHtml: content, text: text });
    },
  },
  mounted() {
    setTimeout(() => {
      this.richEditor.instance.setContent(this.content);
      // 光标定位到Ueditor
      this.richEditor.instance.focus(true);
    }, 1000);
  },
};
</script>

<style lang="less" scoped>
#ueditor_dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #00000088;
  z-index: 99;

  >.content {
    width: 35%;
    height: auto;
    // max-height: 80vh;
    background-color: #fff;
    min-height: 100px;
    margin-top: 10%;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.116),
      0 0 6px rgba(212, 212, 212, 0.294);
    padding: 0 10px;

    >.title {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 15px;
      font-weight: bolder;
    }

    >.colse {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    >.con {
      width: 100%;
      height: 550px;
      overflow-y: auto;
      // padding: 10px;
      box-sizing: border-box;

      >.cc {
        width: 98%;
      }
    }

    >.buts {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      >.but {
        width: auto;
        height: auto;
        padding: 5px 20px;
        background-color: #38a5ee;
        color: #fff;
        box-sizing: border-box;
        font-size: 15px;
        cursor: pointer;
        border-radius: 10px;
      }
    }
  }

  /deep/ .gapfilling-span {
    /* background-color: antiquewhite; */
    display: inline-block;
    /* text-decoration: underline; */
    border-bottom: 1px solid #444;
    padding: 0 30px;
    margin: 0 5px;
  }
}
</style>