<template>
  <div id="project-list" v-loading="loading">
    <!-- 行程管理列表 -->
    <top-value
      :topSearchData="topSearchData"
      :topButtonData="topButtonData"
      @showTopButtonData="showTopButtonData"
      @topButClick="topButClick"
    ></top-value>
    <div class="content">
      <div class="table">
        <el-table
          :data="data"
          stripe
          style="width: 100%"
          height="100%"
          @row-click="handelRowClick"
        >
          <el-table-column label="序号" type="index" width="55">
          </el-table-column>
          <el-table-column
            :prop="menu.key"
            :label="menu.title"
            :width="menu.width"
            v-for="(menu, index) in tableMenu"
            show-overflow-tooltip
            :key="index"
          >
            <template slot-scope="scope">
              <span
                v-if="menu.showDetail"
                :style="{
                  color: ' #409EFF',
                  cursor: 'pointer',
                }"
                @click="showDetail(scope.row)"
              >
                {{
                  scope.row[menu.key] == null || scope.row[menu.key] == ""
                    ? "-"
                    : "查看详情"
                }}
              </span>
              <span
                v-else
                :style="{
                  color: menu.isColor
                    ? returnStatusColor(menu.key, scope.row[menu.key])
                    : '',
                }"
              >
                {{
                  menu.isChange
                    ? returnStatusText(menu.key, scope.row[menu.key])
                    : scope.row[menu.key] == null || scope.row[menu.key] == ""
                    ? "-"
                    : scope.row[menu.key]
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop=" address" label="操作" width="220">
            <template slot-scope="scope">
              <el-button
                @click.native.stop="editClick(scope.row)"
                type="primary"
                plain
                size="small"
              >
                <i class="el-icon-edit"></i> 编辑
              </el-button>
              <el-button
                @click.native.stop="deleteClick(scope.row)"
                type="danger"
                plain
                size="small"
              >
                <i class="el-icon-delete"></i> 删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageData.currentPage"
          :page-sizes="pageData.pageSizes"
          :page-size="pageData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>


    <ueditor-dialog
      v-if="ueditorData.flag"
      :width="ueditorData.width"
      :title="ueditorData.title"
      :content="ueditorData.content"
      @cancelDialog="cancelDialog"
      @makeSure="makeSureUeditor"
    >
    </ueditor-dialog>
  </div>
</template>
<script>
import * as routeData from "./route.js";
export default {
  data() {
    return {
      loading: false,
      model: "",
      topSearchData: routeData.getInitTableData().topSearchData,
      topButtonData: routeData.getInitTableData().topButtonData,
      tableMenu: routeData.getInitTableData().tableMenu,
      pageData: {
        currentPage: 1,
        total: 30,
        pageSizes: this.$PAGE_SIZES,
        pageSize: this.$PAGE_SIZE,
      },
      data: [],
      ueditorData: {
        flag: false,
        width: "60%",
        title: "编辑题干",
        content: "",
      },
    };
  },
  methods: {
    //获取行程列表
    async initTableData(id) {
      let res = {
        pageSize: this.pageData.pageSize,
        pageNum: this.pageData.currentPage,
        projectId: id,
      };
      res = this.$total.array2json(this.topSearchData, res);

      let listData = await routeData.getItineraryListByPage(res);
      this.data = listData.list;
      this.pageData.total = listData.total;
    },
    async topButClick(key) {
      switch (key) {
        case "查询":
          this.pageData.currentPage = 1;
          await this.initTableData();
          break;
        case "添加":
          let tag = this.$menu.returnTag(
            "编辑行程",
            { id: this.id },
            "/route-edit",
            "行程列表"
          );
          this.$store.dispatch("tagsView/addView", tag);
          this.$router.push({
            path: tag.path,
            query: {
              id: "",
              projectId: this.id,
            },
          });
          break;

        default:
          break;
      }
    },
    /**
     * 表格按钮事件
     * @param {*} row
     * @param {*} but
     */

    editClick(row) {
      let tag = this.$menu.returnTag(
        "编辑行程",
        { id: "" },
        "/route-edit",
        "行程列表"
      );
      this.$store.dispatch("tagsView/addView", tag);
      this.$router.push({
        path: tag.path,
        query: {
          id: row.id,
          projectId: this.id,
        },
      });
    },

    async deleteClick(row) {
      let res = {
        idList: [],
      };
      res.idList.push(row.id);
      this.$confirm("此操作将永久删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await routeData.delItineraryList(res);
          this.$message({
            message: "删除成功！",
            type: "success",
          });
          await this.initTableData();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 行点击事件
     * @param {*} row
     * @param {*} column
     * @param {*} event
     */
    handelRowClick(row, column, event) {},
    showDetail(row) {
      // 显示加载动画
      this.loadingInstance = this.$loading({
        text: "加载中...",
        spinner: "el-icon-loading",
        background: 'rgba(255, 255, 255, 0)', // 可设置为透明背景
        fullscreen: false // 不使用全屏遮罩
      });
      this.ueditorData.flag = true;

      setTimeout(() => {
        this.ueditorData.title = "查看行程内容";
        this.ueditorData.content = row.content;
        this.ueditorData.flag = true;
        // 数据显示完毕，关闭加载动画
        this.loadingInstance.close();
      }, 500);
    },
    cancelDialog() {
      this.ueditorData.flag = false;
    },
    makeSureUeditor() {
      this.ueditorData.flag = false;
    },

    showTopButtonData(val) {
      this.topButtonData = val;
    },

    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.initTableData();
    },
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.initTableData();
    },
    returnStatusColor(key, status) {
      return this.$base.getStatusNameByNum(this.model + "Color_" + key, status);
    },
    returnStatusText(key, status) {
      return this.$base.getStatusNameByNum(this.model + "Text_" + key, status);
    },
  },
  async mounted() {
    this.id = this.$route.query.id ? this.$route.query.id : "";
    this.initTableData(this.id);
  },
};
</script>
<style lang="less" scoped>
#project-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > .content {
    width: 100%;
    height: 0;
    flex: 1;
    background-color: #fff;
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    > .table {
      width: 100%;
      flex: 1;
      height: 0;
    }

    > .page {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
